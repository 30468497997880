import PropTypes from 'prop-types';
import { useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { useParams, useRouteLoaderData } from "react-router-dom";
import { getDocuments } from "../../api/project/project-item-artwork.js";
import { getProjectItemDetails } from "../../api/project/project-item.js";
import { getProjectDetails } from "../../api/project/project.js";
import { FileAction, SmallSection } from "../../components/ui/index.js";
import i18n from '../../i18n.js';
import { formatDate } from "../../library/utilities/intl.js";
import styles from "./files.module.scss";

const FileRows = ({ files, token, firstHeading = i18n.t('Project ID') }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={`${styles.task} ${styles.taskHeading}`}>
        <p className={styles.box}>{firstHeading}</p>
        <p className={styles.name}>{t('File Name')}</p>
        <p className={styles.box}>{t('Date')}</p>
        <p className={styles.action}></p>
      </div>
      {files.map(({ id, name, date, url, type }) => (
        <SmallSection key={url} containerStyles={{ margin: '0' }} colorDark="#E8F2FB" colorLight="#E8F2FB">
          <div className={`${styles.task}`}>
            <p className={styles.box}>{type || id}</p>
            <p className={styles.name}>{name}</p>
            <p className={styles.box}>{formatDate(date)}</p>
            <div className={styles.action}>
              <FileAction url={url} quotationToken={token} />
            </div>
          </div>
        </SmallSection>
      ))}
    </>
  )
}
FileRows.protoTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  token: PropTypes.string.isRequired,
}

const FilesRoot = () => {
  const { quotationToken } = useParams();
  const { project_id, quotation, projectItems } = useRouteLoaderData('DashboardRoot') ?? {}
  const { t } = useTranslation()

  const hasAdditionalQuotations = useMemo(() => projectItems.some(item => item?.item_additional_quotations?.length), [projectItems])

  const getItemDocuments = useCallback((item) => [
    ...item.documents?.visual?.map((file) => ({
      id: item.id,
      type: 'Visuals',
      name: file.name,
      date: file.uploaded_time,
      url: file.url,
    })),
    ...item.documents?.client_artwork?.map((file) => ({
      id: item.id,
      type: 'Client Artwork',
      name: file.name,
      date: file.uploaded_time,
      url: file.url,
    })),
    ...item.documents?.make_arwork?.map((file) => ({
      id: item.id,
      type: 'Make Artwork',
      name: file.name,
      date: file.uploaded_time,
      url: file.url,
    })),
    ...item.documents?.proof?.map((file) => ({
      id: item.id,
      type: 'Proof',
      name: file.name,
      date: file.uploaded_time,
      url: file.url,
    })),
    ...item.documents?.template?.map((file) => ({
      id: item.id,
      type: 'Template',
      name: file.name,
      date: file.uploaded_time,
      url: file.url,
    })),
  ], [projectItems])

  const hasItemDocuments = useCallback((item) => {
    return (
      item?.client_artwork?.length > 0 ||
      item?.make_arwork?.length > 0 ||
      item?.proof > 0 ||
      item?.template > 0
    );
  }, [projectItems])

  return (
    <div className="container wrapper dashboard-wrapper">
      <div className="main" style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
        <div className={`box--white ${styles.projectItem}`}>
          <h4 className='box__heading'>{t('Quotations')}</h4>
          <FileRows files={[{
            id: project_id,
            name: quotation.pdf.approved_quotation,
            date: quotation.createdAt,
            url: quotation.pdf.approved_quotation,
          }]}
            token={quotationToken}
          />
        </div>
        {hasAdditionalQuotations && (
          <div className={`box--white ${styles.projectItem}`}>
            <h4 className='box__heading'>{t('Additional Quotations')}</h4>
            {projectItems.map((item) => {
              return (
                <div key={item.id}>
                  {item?.item_additional_quotations?.length > 0 && (
                    <>
                      <h4>Item Document : {item.product.name}</h4>
                      <FileRows files={item.item_additional_quotations.map((file) => ({
                        id: `${file.id} (${file.status === 4 ? t('Approved') : t('Waiting Approval')})`,
                        name: file.pdf.quotation,
                        date: file?.createdAt,
                        url: file.pdf.quotation,
                      }))} token={quotationToken} firstHeading={t('Quotation No.')} />
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <div className={`box--white ${styles.projectItem}`}>
          <h4 className='box__heading'>{t('Other Documents')}</h4>
          {projectItems.map((item) => (
            (
              <div key={item.id}>
                {hasItemDocuments(item) && (
                  <>
                    <h5>Item Document : {item.product.name}</h5>
                    <FileRows files={getItemDocuments(item)} token={quotationToken} firstHeading={t('Type')} />
                  </>
                )}
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

FilesRoot.Loader = async ({ params }) => {
  const { quotationToken } = params;
  const {
    items,
    id: project_id,
    quotation,
  } = await getProjectDetails(quotationToken);

  const projectItems = await Promise.all(
    items.map(({ id }) => getProjectItemDetails(quotationToken, id))
  );
  const projectDocs = await Promise.all(
    items.map(({ id }) => getDocuments(quotationToken, id))
  );
  const projectItemDetails = projectItems.map((item) => ({
    documents: projectDocs.find(({ id }) => item.id === id),
    ...item,
  }));

  return {
    projectItems: projectItemDetails,
    project_id,
    quotation,
  };
};

export default FilesRoot;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, useParams, useRouteLoaderData } from 'react-router-dom';
import { toast } from 'react-toastify';
import { approveArtwork, approveArtworkMadeByLP, getProjectItemDetails, rejectAndRequestChange, rejectArtworkMadeByLP, requestSampleArtwork } from '../../api/project';
import { Steps } from '../../components/Steps';
import { ProjectItem } from '../../components/project';
import i18n from '../../i18n.js';
import { generateRouteUrl, ItemClientStatuses } from '../../library/constants';
import style from './client-artwork.module.scss';
import { StepBody } from './index';

const Dashboard = () => {
    const { quotationToken } = useParams()
    const { projectItems } = useRouteLoaderData('DashboardRoot') ?? {}
    const { t } = useTranslation();
    return (
        <div className="wrapper dashboard-wrapper">
            <div className="main">
                {projectItems && projectItems.map((item, index) => (
                    <div key={item.id ?? index}>
                        <ProjectItem item={item} quotationToken={quotationToken} />
                        <div>
                            <div className={style.stepBar}>
                                <div style={{ 'width': "50%", 'display': 'inline-block' }}>
                                    <Steps
                                        current_step={item.client_status}
                                        stateChanger={() => { }}
                                        items={[
                                            {
                                                title: t('Upload Artwork'),
                                                icon: <img src="/icons/upload-artwork.svg" />,
                                                index: ItemClientStatuses.ORDER_CONFIRMATION,
                                            },
                                            {
                                                title: t('Artwork in review'),
                                                icon: <img src="/icons/artwork-inreview.svg" />,
                                                index: ItemClientStatuses.UPLOAD_ARTWORK,
                                            },
                                            {
                                                title: t('Final Approval'),
                                                icon: <img src='/icons/final-approval.svg' />,
                                                index: ItemClientStatuses.FINAL_APPROVAL,
                                            },
                                            {
                                                title: t('In Production'),
                                                icon: <img src='/icons/in-production.svg' />,
                                                index: ItemClientStatuses.IN_PRODUCTION,
                                            },
                                            {
                                                title: t('In Transit '),
                                                icon: <img src='/icons/in-transit.svg' />,
                                                index: ItemClientStatuses.IN_TRANSIT,
                                            },
                                            {
                                                title: t('Delivered'),
                                                icon: <img src='/icons/delivered.svg' />,
                                                index: ItemClientStatuses.DELIVERED,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            <StepBody data={item} quotationToken={quotationToken} stateChanger={() => { }} />
                        </div>
                    </div>
                ))}
            </div>
        </div >
    )
}

Dashboard.Actions = {
    requestartworksample: async ({ params, data }) => {
        const { quotationToken } = params
        const { itemId } = data
        try {
            const msg = await requestSampleArtwork(quotationToken, itemId)
            toast.success(i18n.t(msg))
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))
        } catch (e) {
            toast.error(i18n.t(e?.message))
        }

    },

    approveProof: async ({ params, data }) => {
        const { quotationToken } = params
        const { itemId } = data
        try {
            const msg = await approveArtwork(quotationToken, itemId)
            toast.success(i18n.t(msg))
            const projectItem = await getProjectItemDetails(quotationToken, itemId)
            if (!projectItem.client_confirm_address) {
                return redirect(generateRouteUrl('ProjectItemAddress', {
                    quotationToken,
                    itemId
                }))
            }
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))
        }
        catch (e) {
            toast.error(i18n.t(e?.message))
        }
    },
    rejectProof: async ({ params, data }) => {
        const { quotationToken } = params
        const { itemId, comment } = data
        try {
            const msg = await rejectAndRequestChange(quotationToken, itemId, { comment })
            toast.success(i18n.t(msg))
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))
        }
        catch (e) {
            toast.error(i18n.t(e?.message))

        }
    },

    approveMakeArtwork:
        async ({ params, data }) => {
            const { quotationToken } = params
            const { itemId } = data
            try {
                const msg = await approveArtworkMadeByLP(quotationToken, itemId)
                toast.success(i18n.t(msg))
                return redirect(generateRouteUrl('Dashboard', { quotationToken }))
            }
            catch (e) {
                toast.error(i18n.t(e?.message))
            }
        },

    rejectMakeArtwork: async ({ params, data }) => {
        const { quotationToken } = params
        const { itemId, rejectComment } = data
        try {
            const msg = await rejectArtworkMadeByLP(quotationToken, itemId, { comment: rejectComment })
            toast.success(i18n.t(msg))
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))
        }
        catch (e) {
            toast.error(i18n.t(e?.message))

        }
    },



}

// Dashboard.Loader = async ({ params }) => {
//     const { quotationToken } = params
//     const { items } = await getProjectDetails(quotationToken)
//     const projectItems = await Promise.all(items.map(({ id }) => getProjectItemDetails(quotationToken, id)))
//     const projectDocs = await Promise.all(items.map(({ id }) => getDocuments(quotationToken, id)))
//     const projectItemDetails = projectItems.map(item => ({
//         documents: projectDocs.find(({ id }) => item.id === id),
//         ...item,
//     }))

//     return {
//         projectItems: projectItemDetails,
//     }
// }

export default Dashboard
/* 
<Steps steps={[
    {
        name: "uploadartwork",
        icon: "/icons/steps/image.svg",
        label: "Upload Artwork"
    },
    {
        name: "uploadartwork1",
        icon: "/icons/steps/image2.svg",
        label: "Upload Artwork"
    },
    {
        name: "upload3",
        icon: "/icons/steps/image2.svg",
        label: "Upload Artwork"
    },
    {
        name: "upload4",
        icon: "/icons/steps/image2.svg",
        label: "Upload Artwork"
    },
    {
        name: "upload6",
        icon: "/icons/steps/image2.svg",
        label: "Upload Artwork"
    },
]} /> */
import dayjs from 'dayjs';

export const DateFormatted = (date) => {
    const dateObj = new Date(date)
    if (isNaN(dateObj)) {
        return "NA"
    }
    return new Intl.DateTimeFormat('de-DE').format(dateObj)
}

export const subtractDaysFromDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}

export const AddOrSubractDays = (startingDate, number, add) => {
    if (add) {
        return new Date(new Date().setDate(startingDate.getDate() + number));
    } else {
        return new Date(new Date().setDate(startingDate.getDate() - number));
    }
}

export const DifferenceBetweenDates = (date1, date2) => {
    // Check if either date1 or date2 is null or undefined
    if (date1 == null || date2 == null) {
        return null
    }

    // Parse the dates using dayjs
    const d1 = dayjs(date1);
    const d2 = dayjs(date2);

    // Check if the dates are valid
    if (!d1.isValid() || !d2.isValid()) {
        return null
    }

    // Calculate the difference in days
    return d2.diff(d1, 'day');
};
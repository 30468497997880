import PropTypes from 'prop-types';
import React, { useState } from 'react';
import i18n from '../../i18n.js';
import Modal from './modal.jsx';

const Confirm = ({ title, description, onConfirm, onCancel, confirmButtonText = i18n.t("Confirm"), cancelButtonText = i18n.t('Cancel'), confirmButtonProps, cancelButtonProps, children, ...otherProps }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleOpen = () => {
        setIsVisible(true);
    };

    const handleClose = () => {
        setIsVisible(false);
        if (onCancel) onCancel();
    };

    const handleConfirm = () => {
        setIsVisible(false);
        if (onConfirm) onConfirm();
    };

    return (
        <>
            <button onClick={handleOpen} {...otherProps}>{children}</button>
            <Modal
                isVisible={isVisible}
                title={title}
                onClose={handleClose}
                closeButtonText={cancelButtonText}
                oKButtonText={confirmButtonText}
                onOk={handleConfirm}
                okButtonProps={confirmButtonProps}
                cancelButtonProps={cancelButtonProps}
            >
                <div>{description}</div>
            </Modal>
        </>
    );
};

Confirm.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    children: PropTypes.node,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confirmButtonProps: PropTypes.object,
    cancelButtonProps: PropTypes.object,
};

export default Confirm;
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, useLoaderData, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { QuotationItemPreview } from '../../components/quotation';
import { Modal } from '../../components/ui';
import { DELIVERY_TYPES, Entities } from '../../library/constants';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { AddOrSubractDays, formatDate, formatPrice } from '../../library/utilities';
import { LocalStorage } from '../../library/utilities/localstorage.js';
import styles from './quotation-preview.module.scss';

const Address = ({ address }) => (
    <>
        {address.street_number} {address.street}<br />
        {address.city}<br />
        {!!address.county && `${address.county}`}<br />
        {address.zipcode}<br />
        {address.country.name}<br />
    </>
)

const QuotationPreview = () => {
    const { t } = useTranslation()
    const { quotationToken } = useParams()
    const { selectedItems } = useLoaderData()
    const { quotation, userLanguage } = useRouteLoaderData('QuotationRoot')

    const quotationSelected = useMemo(() => ({
        ...quotation,
        items: quotation.items.map((item) => ({
            ...item,
            price: item.prices.find(({ id }) => {
                return id === selectedItems?.[item.id]
            })
        })).filter(({ price }) => !!price)
    }), [selectedItems, quotation])

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const modalTimer = setTimeout(() => {
            setIsConfirmModalVisible(true);
        }, 3 * 1000);

        return () => {
            clearTimeout(modalTimer);
        };
    }, []);

    const totalVat = quotationSelected.items.reduce((acc, { price }) => acc + parseFloat(price.vat?.vat_amount ?? 0), 0)
    const selectedItemsTotal = quotationSelected.items.reduce((acc, { price }) => acc + parseFloat(price.total_price_excl_vat), 0) + totalVat

    const handleSignClick = () => {
        //Show quotation preview
        navigate(generateRouteUrl('QuotationSignature', { quotationToken }))
    }

    return (
        <div className="wrapper quotation-wrapper">
            <Modal title={t("Review Quotation")} onClose={() => setIsConfirmModalVisible(false)} isVisible={isConfirmModalVisible}>
                <p>
                    {t("Dear {{first_name}} {{last_name}},", {
                        first_name: quotationSelected.contact.first_name,
                        last_name: quotationSelected.contact.last_name
                    })}
                </p>
                <p>
                    {t("Please review the quotation details and sign it")}.
                </p>
                <div className="actions button--primary" onClick={() => setIsConfirmModalVisible(false)}>{t("Continue")}</div>
            </Modal>
            <div className="main">
                <div className="inner-wrapper">
                    <div className="inner-wrapper--title">{t("Quotation Final Preview")}</div>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        <div className={styles.header}>
                            <div className={styles.logo}>
                                <img src='/images/logo.svg' alt="Leoprinting" />
                            </div>
                            <img src="/images/tagline.svg" alt="tagline" />
                        </div>
                        <div className={`box--large box--light-gray`}>
                            <div className="col" style={{ gridRow: "1/3" }}>
                                <h3>{quotationSelected.customer.company}</h3>
                                <Address address={quotationSelected.address} />
                            </div>
                            <div className="col">
                                <h3>{t('Quotation Reference')}</h3>
                                {quotationSelected.id}
                            </div>
                            <div className="col">
                                <h3>{t("Contact Name")}</h3>
                                {quotationSelected.primary_contact.first_name} {quotationSelected.primary_contact.last_name}
                            </div>
                            <div className="col">
                                <h3>{t("Quotation Date")}</h3>
                                {formatDate(quotationSelected.createdAt)}
                            </div>
                            <div className="col">
                                <h3>{t("Quotation Valid Upto")}</h3>
                                {formatDate(AddOrSubractDays(new Date(quotationSelected.createdAt), 30, true))}
                            </div>
                        </div>
                        <div className="box--large">
                            <p>{t("Dear")} {quotationSelected.primary_contact.first_name} {quotationSelected.primary_contact.last_name},</p>
                            <p>{t("It is our pleasure to make the following quotation:")}</p>
                        </div>
                        {quotationSelected.items.map(item => <QuotationItemPreview key={item.id} item={item} currency={quotationSelected.currency} />)}
                    </div>
                </div>
                <div className="inner-wrapper" style={{ marginTop: 'var(--space-xxlarge)' }}>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        {quotationSelected.client_comment?.length > 0 && (
                            <div className="box--large box--light-gray">
                                <h3>{t("Client Note")}</h3>
                                {quotationSelected.client_comment}
                            </div>
                        )}
                        <div className="box--light-gray" style={{ gap: 'var(--space-large)' }}>
                            <div className="box--dark-gray col">
                                <h4>{t("File Delivery")}</h4>
                                <p>{t("PDf or EPS file. Any questions? Please contact us")}</p>
                                <p></p>
                                <h4>{t("Delivery")}</h4>
                                {quotationSelected.delivery_addresses?.type === DELIVERY_TYPES.MULTIPLE ? (
                                    <p>{t("Registered {{delivery_count}} deliveries to {{countries}}", { delivery_count: quotationSelected.delivery_addresses?.deliveries?.length, countries: quotationSelected.delivery_addresses?.deliveries?.map(({ address }) => address?.country?.name)?.join(', ') })}</p>
                                ) : quotationSelected.delivery_addresses?.type === DELIVERY_TYPES.BULK ? (
                                    <p>{t("Registered bulk delivery to multiple addresses")}</p>
                                ) : (
                                    <p>{t("Registered delivery to {{country}}", { country: quotationSelected.delivery_addresses?.deliveries?.[0]?.address?.country?.name })} </p>
                                )}
                                <p></p>
                                <h4>{t("Delivery Time")}</h4>
                                <p>{t("2 to 3 weeks after approval of the proof. A shorter delivery time can be arranged")}.</p>
                            </div>
                            <div className="box--dark-gray col">
                                <h4>{t("Prices")}</h4>
                                <p>{t("Inclusive setup costs")}</p>
                                <p>{t("Inclusive studio support")}</p>
                                <p>{t("Inclusive digital proof for approval")}</p>
                                <p>{t("Exclusive VAT")}</p>
                            </div>
                        </div>
                        <div className="box--large box--light-gray">
                            <p>{t("This quotation is valid for 30 days from the date of stated on the quotation. Please note that additional costs may apply based on Brexit Ruling. Please notify us of any mistakes. Your business is of great value to us and we hope we can be of service to you. The image(s) in this quotation are an indication only, the customised production will deviate from this.")}</p>
                            <h3>{t("Items and Services")}</h3>
                            <div className={styles.totals}>
                                <table className={styles.totalRow}>
                                    <thead>
                                        <tr>
                                            <th>{t("Item Name")}</th>
                                            <th>{t("Quantity")}</th>
                                            <th>{t("Sales Price/Piece")}</th>
                                            <th>{t("VAT")}</th>
                                            <th>{t("Total Price")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quotationSelected.items.map(item => (
                                            <tr key={item.id}>
                                                <td>{item.product.name}</td>
                                                <td>{item.price.quantity}</td>
                                                <td>{formatPrice(item.price.sales_price, quotationSelected.currency.code)}</td>
                                                <td>{!!item.price.vat && `${item.price.vat.vat_rate}%`}</td>
                                                <td>{formatPrice(item.price.total_price_excl_vat, quotationSelected.currency.code)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr style={{ borderBottom: '1px var(--color-green-line) solid' }}></tr>
                                        <tr>
                                            <td>{t('Sub Total')}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{formatPrice(selectedItemsTotal - totalVat, quotation.currency?.code)}</td>
                                        </tr>
                                        {quotationSelected.items.map(item => (
                                            !!item.price.vat && (
                                                <tr key={item.id}>
                                                    <td><i>{t('Total')} - {item.price.vat?.vat_rate}%</i></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{formatPrice(item.price.vat?.vat_amount, quotation.currency?.code)}</td>
                                                </tr>
                                            )
                                        ))}
                                        <tr>
                                            <td>{t('Total VAT')}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{formatPrice(totalVat, quotation.currency?.code)}</td>
                                        </tr>
                                        <tr>
                                            <th colSpan="4">{t("Net Amount")}</th>
                                            <th>{formatPrice(selectedItemsTotal, quotationSelected.currency.code)}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        {[Entities.NL, Entities.BE, Entities.DE].includes(quotation.entity_id) && (
                            <div className="box--large box--light-gray">
                                <h3>{t('Important Notice Regarding VAT')}:</h3>
                                <p>{t('This order confirmation includes VAT by default. If the order qualifies for an intra-Community supply or export and you have a valid VAT number, you can provide it to us. In that case, we will issue the final invoice without VAT. Please provide us with the VAT number as soon as possible.')}</p>
                            </div>
                        )}
                        <div className="box--large box--light-gray">
                            <h3>{t("Signature")}</h3>
                            <p>{t("Clicking 'Sign' will initiate a verification process. You'll receive an email with a unique verification number")}</p>
                            <div className={`box--large ${styles.signatureContainer}`}>
                                <div className={styles.contact}>
                                    <div className={styles.name}>{quotationSelected.primary_contact.first_name} {quotationSelected.primary_contact.last_name}</div>
                                    <div className={styles.email}>{quotationSelected.primary_contact.email}</div>
                                </div>
                                <button onClick={handleSignClick} className="button--primary">{t("Click to Sign")}</button>
                            </div>
                            <p><strong>{t("Contact")} :</strong></p>
                            <div className={styles.name}>{quotationSelected.primary_contact.first_name} {quotationSelected.primary_contact.last_name}</div>
                            <div className={styles.email}>{quotationSelected.primary_contact.email}</div>
                        </div>
                        <div className="box center">
                            <p><strong>{t("Passion for creating your excellent promotion.")} </strong></p>
                            <p>{t("80-83 Long Lane, London EC1A 9ET  -  Tel: +44 20 3608 1945  -  E-mail: info@leoprinting.com")}<br />{t("Private Limited Company Number 765685")}</p>
                            <hr style={{ width: '40%', minWidth: '250px' }} />
                            <p>{t("Leoprinting is part of Leogroup")}.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


QuotationPreview.Loader = async ({ params }) => {
    const { quotationToken } = params
    const selectedItems = LocalStorage(quotationToken).get('selectedQuotationItems') || {}

    if (isEmpty(selectedItems)) {
        return redirect(generateRouteUrl('QuotationDetails', { quotationToken }))
    }

    return { selectedItems }
}

export default QuotationPreview

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router-dom';
import { Image } from '../../components/ui';
import { getCmsFileUrl } from '../../library/constants/index.js';
import { formatPrice } from '../../library/utilities/intl.js';
import styles from './quotation-rejected.module.scss';

const QuotationRejected = () => {
    const { t } = useTranslation()
    const { quotation } = useRouteLoaderData('QuotationRoot') ?? {}

    return (
        <div>
            <div className="wrapper quotation-wrapper">
                <div className="main">
                    {!!quotation.items && quotation.items.map(item => (
                        <div key={item.id} className={styles.itemWrapper}>
                            <div className={styles.left}>
                                <div className={styles.image}>
                                    {item.product.photos && <Image src={getCmsFileUrl(item.product?.photos?.[0])} />}
                                    <i style={{ fontSize: '0.8rem' }}>{t('Image is for illustration.Real product may vary')}</i>
                                </div>
                            </div>
                            <div className={styles.right}>
                                <h2>{item.product.name}</h2>
                                <div className={styles.specifications}>
                                    {item.specifications.map(({ name, value }) => (
                                        <React.Fragment key={name}>
                                            <div className={styles.name}>{name}</div>
                                            <div className={styles.value}>{value}</div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className={styles.quantities}>
                                    <div className={`${styles.quantityHeading} ${styles.quantityRow}`}>
                                        <div>{t('Quantity')}</div>
                                        <div>{t('Sales Price/Piece')}</div>
                                        <div>{t('Total Amount (excl. VAT)')}</div>
                                    </div>
                                    {item.prices.map(price => (
                                        <div className={styles.quantityRow} key={price.id}>
                                            <div>{price.quantity}</div>
                                            <div>{formatPrice(price.sales_price, quotation.currency.code)}</div>
                                            <div>{formatPrice(price.total_price_excl_vat, quotation.currency.code)}</div>
                                        </div >
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="sidebar">
                    <div className="box--gray box--small ">
                        <h3>{t('Quotation Rejected')}</h3>
                        <p><strong>
                            <Trans
                                defaults='Quotation is rejected by user. For more information, please contact <EmailLink/>'
                                components={{ EmailLink: <a href="mailto:admin@leoprinting.com">admin@leoprinting.com</a> }}
                            />
                        </strong></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuotationRejected
